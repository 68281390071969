<template>
    <!-- form -->
    <form class="validate-form">


    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Email templates</h4>
            <base-card-drop-down>
                <template #title>
                    Edit mail templates
                </template>
                <template #default>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-check-circle bs-icon-tiny" ></i>
                        Rejection mail
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-check-circle bs-icon-tiny" ></i>
                        Progress mail
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-circle bs-icon-tiny" ></i>
                        Success mail
                    </a>
                </template>
            </base-card-drop-down>
        </template>
        <template #default>
                <div class="row">
                    <div class="col-12">
                        <div class="mb-1">
                            <label class="form-label" for="accountTextarea">Rejection mail</label>
                            <textarea class="form-control" id="accountTextarea" rows="4" placeholder="Your Bio data here..."></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="mb-1">
                            <label class="form-label" for="accountTextarea">Progress mail</label>
                            <textarea class="form-control" id="accountTextarea" rows="4" placeholder="Your Bio data here..."></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="mb-1">
                            <label class="form-label" for="accountTextarea">Successful candiate mail</label>
                            <textarea class="form-control" id="accountTextarea" rows="4" placeholder="Your Bio data here..."></textarea>
                        </div>
                    </div>
                </div>
        </template>


    </base-card-plain>
    <!--Core job detail -->




    </form>
    <!--/ form -->
</template>

<script>
// import components
import BaseCardPlain from '@/components/ui/BaseCardPlain.vue';
import BaseCardDropDown from '@/components/ui/BaseCardDropDown.vue';

export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    },
    components:{
        BaseCardPlain,
        BaseCardDropDown
    }
}


</script>
